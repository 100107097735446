import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function SalsaritasRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <h1>Eat Salsarita’s, Earn Rewards!</h1>

      <Image
        alt="Salsarita's Rewards"
        height={120}
        src={getS3Path(slug, 'images/salsaritas-rewards.png')}
        width={405}
      />

      <p>
        Your loyalty is rewarded in different ways! Receive in-app offers and rewards as you dine at
        or cater with Salsarita’s. Offers include Free Combo Upgrades, Birthday Burritos, extra
        points, & more!
      </p>
      <p>
        For more details visit our{' '}
        <a href="https://salsaritas.com/rewards/" target="_blank" rel="noreferrer">
          rewards page.
        </a>
      </p>

      <p>
        <small>*Valid on dine-in, take-out, and online purchases.</small>
      </p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  padding: '20px',
  textAlign: 'center',

  img: {
    margin: '0 auto',
    maxHeight: '150px',
  },

  h1: {
    lineHeight: '110%',
    fontFamily: theme.secondary_font.regular.font_family,
    margin: '0 0 30px',
    ...getFontStyles(theme.text.secondary_font, ['font_size']),
  },

  p: {
    fontFamily: theme.primary_font.regular.font_family,
    ...getFontStyles(theme.text.secondary_font, ['font_size']),

    small: {
      fontSize: '0.75em',
    },
  },

  a: {
    textDecoration: 'underline',
    color: theme.buttons.primary_button_background_color,
  },
}));
