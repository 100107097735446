import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function PolloCamperoRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <Image
        alt="Pollo Campero loyalty"
        height={230}
        src={getS3Path(slug, 'images/pollo_campero_how_rewards_work.png')}
        width={294}
      />
      <h1>Join our Loyalty Club!</h1>
      <p>
        As a member of this extremely tasty club you earn points with every purchase (online and
        in-store!) in addition to exclusive member-only offers, birthday gift, and referral awards!
        Download the Campero app and join today!
      </p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  margin: '20px 0 0',
  padding: '20px',
  ...getFontStyles(theme.text.primary_font, ['color']),

  img: {
    width: '100%',
  },

  h1: {
    fontFamily: theme.primary_font.bold.font_family,
    fontSize: '24px',
  },

  p: {
    fontFamily: theme.primary_font.regular.font_family,
    fontSize: '18px',
  },
}));
