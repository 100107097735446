import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function PokeworksRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <Image
        alt="Pokeworks Loyalty Program"
        height={200}
        src={getS3Path(slug, 'images/PW_LoyaltyModalIcon_400x400_3.png')}
        width={200}
      />
      <h1>Earn $9 when you spend $99!</h1>
      <p>
        Use your Pokeworks Rewards account to earn points with every purchase. Just order online,
        in-app, scan your QR code at the register or manually enter your receipt.
      </p>
      <p>Save time and order ahead or get your poke delivered for only $1.99!</p>
      <h1>Redeem rewards</h1>
      <p>
        Select from the available rewards on your account and order online, in-app or scan your QR
        code at the cash register to apply it to your order.
      </p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  fontFamily: theme.primary_font.regular.font_family,
  padding: '20px',
  textAlign: 'center',

  img: {
    maxHeight: '200px',
    maxWidth: '200px',
  },

  h1: {
    fontFamily: theme.primary_font.bold.font_family,
    fontSize: '24px',
    ...getFontStyles(theme.text.primary_font, ['color']),
  },

  p: {
    color: '#84756e',
    fontSize: '18px',
  },
}));
