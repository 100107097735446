import styled from 'styled-components';
import { Image } from '@/components/image';
import { LAYOUT } from '@/constants/styles';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function PfChangsRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <div>
        <Image
          alt="P.F. Changs Rewards Platinum"
          height={98}
          src={getS3Path(slug, 'images/PFCRewards-Platinum.png')}
          width={300}
        />
        <Image
          alt=""
          role="presentation"
          height={60}
          src={getS3Path(slug, 'images/icons/EnjoyMoreIcon.png')}
          width={49}
        />
        <Header>EARN MORE</Header>
        <Paragraph>20 points with every $1 spent</Paragraph>
        <Paragraph>(2x more than Gold Rewards Members)</Paragraph>
      </div>

      <div>
        <Image
          alt=""
          role="presentation"
          height={60}
          src={getS3Path(slug, 'images/icons/EarnMoreIcon.png')}
          width={32}
        />
        <Header>ENJOY MORE</Header>
        <Paragraph>Free delivery with every order</Paragraph>
        <Paragraph>$15 Reward with every 2,000 points earned</Paragraph>
      </div>
      <div>
        <Image
          alt=""
          role="presentation"
          height={60}
          src={getS3Path(slug, 'images/icons/VIPIcon.png')}
          width={62}
        />
        <Header>AS A VIP</Header>
        <Paragraph>
          Birthday Rewards, priority reservations, VIP concierge access &#38; more - exclusively for
          Platinum Members
        </Paragraph>
        <Image
          alt=""
          role="presentation"
          height={41}
          src={getS3Path(slug, 'images/Platinum-DecorativeLine.png')}
          width={200}
        />
        <Paragraph>
          Visit our&nbsp;
          <Link
            as="a"
            href="https://www.pfchangs.com/REWARDS"
            target="_blank"
            rel="noreferrer"
            aria-label="Go to Rewards page"
          >
            Rewards page
          </Link>
          &nbsp;to learn more &#38; join.
        </Paragraph>
      </div>
    </StyledModalContent>
  );
}

const Paragraph = styled.p(({ theme }) => ({
  fontSize: '18px',
  margin: 0,
  fontFamily: theme.primary_font.regular.font_family,
  ...getFontStyles(theme.text.primary_font, ['color']),
}));

const Link = styled(Paragraph)`
  text-decoration: underline;
`;

const Header = styled.h1(({ theme }) => ({
  fontFamily: theme.secondary_font.bold.font_family,
  fontSize: '18px',
  marginTop: 0,
  ...getFontStyles(theme.text.secondary_font, ['color']),
}));

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-7);
  background-color: black;
  font-family: ${({ theme }) => theme.primary_font.regular.font_family};
  padding: ${LAYOUT.GUTTER}px;
  text-align: center;
`;
