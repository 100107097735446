import { Component, type ErrorInfo } from 'react';
import * as ErrorReporter from '@/utils/errorReporter';

class GenericErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, 'ERROR');
    console.log(errorInfo, 'ERROR INFO');

    // Fire error event
    ErrorReporter.captureException(error, 'error');
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default GenericErrorBoundary;
