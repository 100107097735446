import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function ScratchKitchenRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <Image
        alt="Treat Yo Self"
        height={189}
        src={getS3Path(slug, 'images/Treat_Yo_Self-01.svg')}
        width={200}
      />
      <h1>1: Earn Points with Every Purchase</h1>
      <p>
        Every time you place an order through ScratchKitchen.com you will earn points that can be
        cashed in for rewards.
      </p>
      <h1>2: Use Points to Redeem Rewards</h1>
      <p>
        Your available rewards are visible on your account page and will appear at checkout so that
        you can easily select and apply them to your order.
      </p>
      <p>
        For more details visit our&nbsp;
        <a
          href="https://www.scratchkitchen.com/rewards/"
          target="_blank"
          rel="noreferrer"
          aria-label="Go to Rewards page"
        >
          rewards page
        </a>
        .
      </p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  fontFamily: theme.primary_font.regular.font_family,
  padding: '20px',
  textAlign: 'center',

  img: {
    maxHeight: '200px',
    maxWidth: '200px',
  },

  h1: {
    fontFamily: theme.primary_font.bold.font_family,
    fontSize: '24px',
    ...getFontStyles(theme.text.primary_font, ['color']),
  },

  'p,a': {
    color: '#84756e',
    fontSize: '18px',
  },

  a: {
    textDecoration: 'underline',
  },
}));
