import { useEffect, useState } from 'react';
import {
  StyledFlexContainer,
  StyledGenericModal,
  StyledGenericModalContainer,
  StyledGenericModalHeader,
} from './styles';
import { pageViewEventHandler } from '@/analytics/events';
import { ConnectedXButton } from '@/components/uielements/X';
import { GenericFocusTrap } from '@/components/uielements/genericFocusTrap';

interface Props {
  modalOpen: boolean;
  name: string;
  toggleModal?: (show: boolean) => void;
  header?: string | JSX.Element;
  maxWidth?: number;
  confirmMessage?: any;
  cancelMessage?: any;
  disableModalClose?: boolean;
  onClose?: () => void;
}

const GenericModal: React.FC<Props> = ({
  modalOpen,
  name,
  toggleModal,
  header,
  maxWidth,
  disableModalClose,
  onClose,
  children,
}) => {
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    setHeight(window.innerHeight);

    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    const close = (evt: { keyCode: number }) => {
      if (toggleModal && !disableModalClose && evt.keyCode === 27) {
        toggleModal(false);
        onClose && onClose();
      }
    };

    document.addEventListener('keydown', close);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('keydown', close);
      window.removeEventListener('resize', handleResize);
    };
  }, [toggleModal, disableModalClose, onClose]);

  useEffect(() => {
    if (modalOpen) {
      pageViewEventHandler(name);
    }
  }, [modalOpen, name]);

  if (!modalOpen) {
    return null;
  }

  return (
    <GenericFocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: !disableModalClose,
      }}
    >
      <StyledGenericModal>
        <StyledGenericModalContainer maxWidth={maxWidth} height={height}>
          <div>
            {header && (
              <StyledFlexContainer $header>
                <StyledGenericModalHeader tabIndex={0}>
                  {typeof header === 'string' ? <p>{header}</p> : <>{header}</>}
                </StyledGenericModalHeader>
              </StyledFlexContainer>
            )}

            {toggleModal && !disableModalClose && (
              <ConnectedXButton onClick={() => toggleModal(false)} name={name} />
            )}
          </div>

          {children}
        </StyledGenericModalContainer>
      </StyledGenericModal>
    </GenericFocusTrap>
  );
};

export default GenericModal;
