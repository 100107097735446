import { type WebConfig } from '@koala/sdk';
import { readableColor } from 'polished';
import { COLORS } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export function getSafeHoverColor(value: string | null, calculatedFrom: string): string {
  if (!value) {
    return `color-mix(in oklab, ${calculatedFrom}, black 10%)`;
  }

  return value;
}

export function getHeight(size: string | undefined, defaultHeight: number) {
  switch (size) {
    case 'small':
      return '40px';
    case 'medium':
      return '50px';
    case 'large':
      return '57px';

    default:
      return `${defaultHeight}px`;
  }
}

export function getButtonThemeProperties(
  $buttonTheme: string | undefined,
  theme: WebConfig,
  disabled = false,
) {
  let properties = {};

  const {
    knockout_button_background_color,
    knockout_button_background_hover_color,
    knockout_button_border_color,
    knockout_button_border_hover_color,
    knockout_button_border_radius,
    knockout_button_text_hover_color,
    primary_button_background_color,
    primary_button_background_hover_color,
    primary_button_border_color,
    primary_button_border_hover_color,
    primary_button_border_radius,
    primary_button_text_hover_color,
    secondary_button_background_color,
    secondary_button_background_hover_color,
    secondary_button_border_color,
    secondary_button_border_hover_color,
    secondary_button_border_radius,
    secondary_button_text_hover_color,
  } = theme.buttons;

  if ($buttonTheme === 'secondary') {
    properties = {
      background: secondary_button_background_color,
      borderRadius: `${secondary_button_border_radius}px`,
      border: `1px solid ${secondary_button_border_color}`,
      ...getFontStyles(
        theme.buttons.secondary_font,
        ['font_family', 'font_style', 'font_weight', 'color', 'line_height'],
        {
          color: (value) => {
            if (value === 'revert' || value === null) {
              return safePolished(readableColor)(theme.buttons.secondary_button_background_color);
            }

            return value;
          },
        },
      ),

      '&:hover': {
        background: getSafeHoverColor(
          secondary_button_background_hover_color,
          secondary_button_background_color,
        ),
        borderColor: getSafeHoverColor(
          secondary_button_border_hover_color,
          secondary_button_border_color,
        ),
        color:
          secondary_button_text_hover_color ??
          safePolished(readableColor)(secondary_button_background_color),
      },
    };
  } else if ($buttonTheme === 'knockout') {
    properties = {
      background: knockout_button_background_color,
      borderRadius: `${knockout_button_border_radius}px`,
      border: `1px solid ${knockout_button_border_color}`,
      ...getFontStyles(
        theme.buttons.knockout_font,
        ['font_family', 'font_style', 'font_weight', 'color', 'line_height'],
        {
          color: (value) => {
            if (value === 'revert' || value === null) {
              return knockout_button_border_color;
            }

            return value;
          },
        },
      ),

      '&:hover': {
        background: knockout_button_background_hover_color,
        borderColor: getSafeHoverColor(
          knockout_button_border_hover_color,
          knockout_button_border_color,
        ),
        ...getFontStyles(theme.buttons.knockout_font, ['color'], {
          color: (value) => knockout_button_text_hover_color ?? value,
        }),
      },
    };
  } else {
    properties = {
      background: primary_button_background_color,
      borderRadius: `${primary_button_border_radius}px`,
      border: `1px solid ${primary_button_border_color}`,
      ...getFontStyles(
        theme.buttons.primary_font,
        ['font_family', 'font_style', 'font_weight', 'color', 'line_height'],
        {
          color: (value) => {
            if (value === 'revert' || value === null) {
              return safePolished(readableColor)(theme.buttons.primary_button_background_color);
            }

            return value;
          },
        },
      ),

      '&:hover': {
        background: getSafeHoverColor(
          primary_button_background_hover_color,
          primary_button_background_color,
        ),
        borderColor: getSafeHoverColor(
          primary_button_border_hover_color,
          primary_button_border_color,
        ),
        color:
          primary_button_text_hover_color ??
          safePolished(readableColor)(primary_button_background_color), // this has to use the default color, because color-mix will not work with polished.readableColor
      },
    };
  }

  if (disabled) {
    properties = {
      ...properties,
      background: COLORS.DARKGRAY,
      border: 'none',
    };
  }

  return properties;
}

export function getSubmittingProperties(submitting = false, theme: WebConfig): object {
  if (!submitting) {
    return {};
  }

  return {
    ':after': {
      animationName: 'barberpole', // defined
      animationDuration: '2s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      background: `repeating-linear-gradient(
          -55deg,
          ${theme.global.primary_active_color} 1px,
          ${COLORS.PRIMARY_NAVY_XX_LIGHT} 2px,
          ${COLORS.PRIMARY_NAVY_XX_LIGHT} 11px,
          ${theme.global.primary_active_color} 12px,
          ${theme.global.primary_active_color} 22px
        )`,
      bottom: 0,
      content: '""', // content must be double wrapped due to styled components
      height: '100%',
      left: 0,
      opacity: 0.3,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: 'opacity 0.3s ease',
      width: '1025px',
    },
  };
}
