import * as ErrorReporter from '@/utils/errorReporter';
/**
 * A safe wrapper around polished functions that will catch any errors thrown by polished
 * and return a set fallback
 *
 * Default fallback is any empty string because StyledComponents (and CSS) will safely ignore that
 *
 * @param fn polished function
 * @param fallback optional fallback
 * @returns safe wrapper around polished function
 */
export const safePolished =
  (fn: (...args: any[]) => string, fallback = '') =>
  (...args: any[]) => {
    try {
      return fn(...args);
    } catch (error) {
      ErrorReporter.captureException(error, 'error');
      return fallback;
    }
  };
