import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function MooyahRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <ModalContent>
      <Image
        alt="Mooyah rewards"
        height={276}
        src={getS3Path(slug, 'images/MOOYAH-rewards.jpg')}
        width={294}
      />
      <Header>Delicious rewards in every bite!</Header>

      <Paragraph>Score free personal fries with any purchase just for signing up.</Paragraph>
      <Paragraph>Earn points for all your food & drink orders ($1 = 1 point)</Paragraph>
      <Paragraph>Unlock your favorite menu items with your points up to a free burger.</Paragraph>
      <Paragraph>Awesome offers & bonus points dropped all year long.</Paragraph>
      <Paragraph>Be the first to know the latest news & local deals.</Paragraph>
      <Paragraph>
        For more details visit our&nbsp;
        <Link
          href="https://mooyah.com/rewards/"
          target="_blank"
          rel="noreferrer"
          aria-label="Go to Rewards page"
        >
          rewards page
        </Link>
        .
      </Paragraph>
    </ModalContent>
  );
}
const ModalContent = styled.div(({ theme }) => ({
  margin: '20px 0 0',
  padding: '20px',
  ...getFontStyles(theme.text.primary_font, ['color']),
}));

const Header = styled.h1`
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  font-size: 24px;
`;

const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.primary_font.regular.font_family};
  font-size: 18px;
`;

const Link = styled.a`
  text-decoration: underline;
`;
