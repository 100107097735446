import styled from 'styled-components';
import Link from 'next/link';
import { getButtonThemeProperties, getHeight, getSubmittingProperties } from './utils';
import { LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface ButtonProps {
  $isLoading?: boolean;
  size?: string;
  // TODO: improve this type and remove string type to narrow it to proper union type
  $buttonTheme?: 'secondary' | 'knockout' | 'primary' | string;
  $submitting?: boolean;
  $minWidth?: number;
  disabled?: boolean;
}

type ButtonLinkProps = Omit<ButtonProps, '$isLoading' | '$submitting'>;

export const PrimaryButton = styled.button<ButtonProps>(
  {
    // Default button styles
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
    justifyContent: 'center',
    lineHeight: 'normal',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all 150ms linear',
  },
  ({ theme, $isLoading, $minWidth, size, $buttonTheme, disabled, $submitting }) => {
    const { fontSize } = getFontStyles(theme.forms.font, ['font_size']);
    return {
      cursor: $isLoading ? 'default' : 'pointer',
      fontSize: size === 'small' ? '14px' : fontSize,
      height: getHeight(size, theme.forms.field_height),
      $minWidth: $minWidth ? `${$minWidth}px` : '100px',
      opacity: $isLoading ? 0.3 : 1,
      padding: `0 ${LAYOUT.GUTTER}px`,
      pointerEvents: disabled || $submitting ? 'none' : 'auto',
      ...getButtonThemeProperties($buttonTheme, theme, disabled),
      ...getSubmittingProperties($submitting, theme),
    };
  },
);

export const PrimaryButtonLink = styled(Link)<ButtonLinkProps>(
  {
    // Default button styles
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
    justifyContent: 'center',
    lineHeight: 'normal',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all 150ms linear',
  },
  ({ theme, $minWidth, size, $buttonTheme, disabled }) => {
    const { fontSize } = getFontStyles(theme.forms.font, ['font_size']);
    return {
      cursor: 'pointer',
      fontSize: size === 'small' ? '14px' : fontSize,
      height: getHeight(size, theme.forms.field_height),
      $minWidth: $minWidth ? `${$minWidth}px` : '100px',
      opacity: 1,
      padding: `0 ${LAYOUT.GUTTER}px`,
      pointerEvents: disabled ? 'none' : 'auto',
      ...getButtonThemeProperties($buttonTheme, theme, disabled),
    };
  },
);

/*
 * `StyledPrimaryButton` is the actual styled component we use in other components
 * and is necessary so we can attach attributes. We also have the added benefit of defining more
 * obtuse css, like keyframes that are called in the `PrimaryButton` object above
 */
export const StyledPrimaryButton = styled(PrimaryButton)`
  @keyframes barberpole {
    from {
      background-position: 0 0;
    }

    to {
      background-position: 101px 0;
    }
  }
`;
