import styled from 'styled-components';
import { Image } from '@/components/image';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { getS3Path } from '@/utils/s3';

export function LunaGrillRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <Image
        alt="Inspiring Good Food Choices"
        height={147}
        src={getS3Path(slug, 'images/luna-rewards.png')}
        width={294}
      />
      <h1>EAT REAL. GET REWARDED.</h1>
      <h4>
        Enjoy the fresh flavors of real Mediterranean cooking with Luna Grill Mediterranean Kitchen.
        Earn rewards on dine-in, pickup, curbside and delivery orders placed through our app or
        website today!
      </h4>
      <ul>
        <li>Earn 1 point for every $1 you spend.</li>
        <li>Earn 100 points, and get $5 off your next visit!</li>
      </ul>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  /** @FIXME font refactor. (hard coded font family) */
  fontFamily: '"springsteel-bold", "Helvetica" !important',
  padding: '20px',

  h1: {
    color: theme.global.primary_active_color,
  },

  'h4, li': {
    fontSize: '20px',
    ...getFontStyles(theme.text.primary_font, ['color']),
  },

  li: {
    fontSize: '18px',
  },

  ul: {
    textAlign: 'left',
  },
}));
