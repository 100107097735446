import { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import { LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface ButtonProps {
  onClick: any;
  name: string;
  id?: string;
  className?: string;
}

const _XButton = memo(function Button({ id, onClick, name, className }: ButtonProps) {
  const theme = useTheme();
  const { color } = getFontStyles(theme.modals.font);

  return (
    <StyledXButton
      className={className}
      onClick={onClick}
      aria-label={`Click to close ${name}`}
      id={id}
    >
      <XSvg color={color} />
    </StyledXButton>
  );
});

export const ConnectedXButton = _XButton;

const _XSvg = ({ color, size = 15 }: { color?: string; size?: number }) => {
  const theme = useTheme();
  const { color: fallbackColor } = getFontStyles(theme.modals.font);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={'0 0 15 15'}>
      <g fill="none" fillRule="evenodd">
        <path d="M-10-10h34v34h-34z" />
        <g stroke={color ?? fallbackColor} strokeWidth="1.9">
          <path d="M13.304.381L.38 13.304M.38.381l12.924 12.923" />
        </g>
      </g>
    </svg>
  );
};

export const XSvg = _XSvg;

const StyledXButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: none;
  border: none;
  padding: ${LAYOUT.GUTTER}px;

  img {
    display: block;
    height: 24px;
    width: 24px;
  }
`;

const _FilledXSvg = ({ color, size = 20 }: { color?: string; size?: number }) => {
  const theme = useTheme();
  const { color: fallbackColor } = getFontStyles(theme.modals.font);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99121 18.7422C14.9746 18.7422 19.0879 14.6289 19.0879 9.6543C19.0879 4.67969 14.9658 0.566406 9.98242 0.566406C5.00781 0.566406 0.90332 4.67969 0.90332 9.6543C0.90332 14.6289 5.0166 18.7422 9.99121 18.7422ZM6.99414 13.4863C6.52832 13.4863 6.15918 13.1172 6.15918 12.6426C6.15918 12.4316 6.24707 12.2207 6.41406 12.0625L8.80469 9.66309L6.41406 7.27246C6.24707 7.11426 6.15918 6.90332 6.15918 6.69238C6.15918 6.21777 6.52832 5.85742 6.99414 5.85742C7.24023 5.85742 7.43359 5.93652 7.5918 6.09473L9.99121 8.48535L12.3994 6.08594C12.5752 5.91895 12.7598 5.83984 12.9971 5.83984C13.4629 5.83984 13.832 6.20898 13.832 6.6748C13.832 6.89453 13.7441 7.08789 13.5771 7.26367L11.1865 9.66309L13.5771 12.0537C13.7354 12.2207 13.8232 12.4229 13.8232 12.6426C13.8232 13.1172 13.4541 13.4863 12.9795 13.4863C12.7422 13.4863 12.54 13.3984 12.373 13.2402L9.99121 10.8584L7.60938 13.2402C7.45117 13.4072 7.24023 13.4863 6.99414 13.4863Z"
        fill={color ?? fallbackColor}
      />
    </svg>
  );
};

export const FilledXSvg = _FilledXSvg;
