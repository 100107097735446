import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';
import { Image } from '@/components/image';
import GenericModal from '@/components/uielements/genericModal';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { ROUTES } from '@/constants/routes';
import { ORDER } from '@/constants/styles';
import { useSelector } from '@/redux';
import { getS3Path } from '@/utils/s3';

export function DeepFloatingButton() {
  const router = useRouter();
  const [showRules, setShowRules] = useState(false);
  const { toast } = useSelector((state) => state.app.global);
  const { slug } = useSelector((state) => state.app.organization.organization);

  // Hide the button if we're not on the store, checkout, or order confirmation pages.
  if (
    router.pathname !== ROUTES.STORE &&
    router.pathname !== ROUTES.CHECKOUT &&
    router.pathname !== ROUTES.ORDER_CONFIRMATION
  ) {
    return null;
  }

  // Hide this component if a toast is visible since they will clash.
  if (toast.display) {
    return null;
  }

  return (
    <>
      <GenericModal
        header="Reward Rules"
        modalOpen={showRules}
        toggleModal={setShowRules}
        maxWidth={740}
        name="Deep Rewards Rules Modal"
      >
        <StyledRewardsContainer>
          <StyledRewardsImage src="https://loyalty.deepindiankitchen.com/Deep%20-%20Loyalty%20Program%20Rules.png" />
        </StyledRewardsContainer>
      </GenericModal>
      <StyledLink
        onClick={() => setShowRules(true)}
        hideOnMobile={router.pathname === ROUTES.CHECKOUT}
      >
        <Image
          width={24}
          height={24}
          alt="Reward Rules"
          src={getS3Path(slug, 'images/icons/icon-rewards.svg')}
        />
        <span>Reward Rules</span>
      </StyledLink>
    </>
  );
}

interface StyledLinkProps {
  hideOnMobile: boolean;
}

const StyledLink = styled(StyledPrimaryButton)<StyledLinkProps>`
  align-items: center;
  bottom: 20px;
  display: flex;
  height: 60px;
  justify-content: center;
  line-height: 60px;
  padding: 0 30px;
  position: fixed;
  right: 20px;
  transition: all 0.2s linear;
  z-index: ${ORDER.CART - 1};

  span {
    padding-left: 12px;
  }

  @media (max-width: 1024px) {
    padding: 0;
    width: 60px;

    span {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;

const StyledRewardsContainer = styled.div`
  height: 420px;
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 767px) {
    height: 100vh;
    overflow-x: auto;
  }
`;

const StyledRewardsImage = styled.img`
  width: 100%;
  min-width: 420px;
`;
